import React, {useEffect, useState, useGlobal} from "reactn";
import {useParams} from 'react-router-dom'
import {Table, Space, Button, Popconfirm, Input} from 'antd';
import api from '../service/index'
import {Link} from 'react-router-dom';
import qs from 'qs';
import {
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    CheckOutlined,
    CloseOutlined,
    LeftOutlined,
    Loading3QuartersOutlined
} from "@ant-design/icons";

const SessionsItemList = (props) => {
    let params = useParams()
    let query = []
    const {Search} = Input;
    let sessions = params.id !== "add" ? params.id : false;
    query["categoryId"] = sessions;
    let [data, setData] = useState([]);
    let [totalCount, setTotalCount] = useState(0)
    let [selectedRows, setSelectedRows] = useState([])
    let [loading, setLoading] = useState(false)
    let [search, setSearch] = useState(false)
    let [modules] = useGlobal("modules");
    let [languagesCms] = useGlobal("languagesCms"); 
    let [isSmall, ] = useGlobal('isSmall')

    let path = props.location.pathname.split('/')[1];
    let module
    if (modules !== null) {
        [module] = modules.filter(el => el._id === path);
    }

    let handleTableChange = async (page, filters, sorter) => {
        get(page, filters, sorter);
    }

    let get = async (page, filters, sorter) => {
        if (page) {
            page = {page: page.current, pageSize: page.pageSize}
        } else {
            page = {page: 1, pageSize: 10};
        }
        let shortString = 'startDate,startTime';

        if (sorter) {
            if (Object.keys(sorter).length) {
                shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
            }
        }

        let _params = {sort: shortString, ...page, query};

        if (search.length > 2) {
            _params["search"] = search.toLowerCase();
            _params["searchFields"] = "name";
        }
        let restData = await api.get(`/rest/sessions?${qs.stringify(_params)}`, {_params}).then(({data}) => {
            setLoading(false)
            if(data && data.result){
            setTotalCount(data.result.total);
            return data.result.rows.map((item, key) => {
                if (item.active === "1")
                    item.active = <CheckOutlined/>;
                else
                    item.active = <CloseOutlined/>;
                item.key = key;
                return item;
            })}
        });
        setData(restData);
    }

    useEffect(() => {
        if (modules) {
            setLoading(true)
            get();
        }
    }, [search])

    let rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelectedRows(selectedRows);
        },
    };


    let deleteRow = async (item_id) => {
        api.delete(`/rest/sessions/${item_id}`, ({data}) => {
        });
        let newData = data.filter(el => el._id !== item_id);
        setData(newData);
    }
    let deleteSelectedRows = async () => {
        selectedRows.forEach(item => {
            api.delete(`/rest/sessions/${item._id}`, ({data}) => {
            });
            let newData = data.filter(el => el._id !== item._id);
            setData(newData);
            get();
        })
    }
    let onChange = async (e) => {
        setSearch(e.target.value);
        get();
    }

    let columns = [
        {
            title: languagesCms.TITLE,
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name - b.name,
            sortDirections: ['descend', 'ascend']
        }, {
            title:languagesCms.START_DATE,
            dataIndex: 'startDate',
            key: 'startDate',
            sorter: (a, b) => a.startDate - b.startDate,
            sortDirections: ['descend', 'ascend']
        },
        {
            title:languagesCms.END_DATE,
            dataIndex: 'endDate',
            key: 'endDate',
            sorter: (a, b) => a.endDate - b.endDate,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: languagesCms.ACTIVE,
            dataIndex: 'active',
            key: 'active',
            sorter: (a, b) => a.active - b.active,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: languagesCms.GROUP,
            dataIndex: 'groups',
            key: 'groups',
            sorter: (a, b) => a.groups - b.groups,
            sortDirections: ['descend', 'ascend'],
            render: (groups) =>
            groups && groups.length > 0 &&
              groups.map((e, i) => (
                <div key={i} style={{ marginBottom: 5 }}>
                  {e.name}
                </div>
              )),
          },
        {
            title: 'Action',
            key: 'action',
            className: 'editColumn',
            width: 150,
            render: (text, record) => (
                <Space size="small">
                    <Popconfirm
                        onConfirm={() => deleteRow(record._id)} title={languagesCms.CHECK_DELETE}
                        okText={languagesCms.OK_TEXT} cancelText={languagesCms.CANCEL_TEXT}>
                        <Button type="danger" icon={<DeleteOutlined/>}>
                            {isSmall ? '' : languagesCms.DELETE}
                        </Button>
                    </Popconfirm>
                    <Link to={"/sessions/detail/" + sessions + "/edit/" + record._id}>
                        <Button icon={<EditOutlined/>}>´{isSmall ? '' : languagesCms.EDIT}</Button>
                    </Link>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""}</h1>
                </div>
                <div className="list-buttons">
                    {!isSmall && (
                        <Button type="danger" icon={<DeleteOutlined/>} size="large" onClick={deleteSelectedRows}
                                style={{marginRight: "5px"}}>{languagesCms.DELETE_SELECTED}</Button>
                    )}
                    <Link to={"/sessions/detail/" + sessions + "/add"}>
                        <Button type="light" icon={<PlusOutlined/>} size="large"
                                style={{marginRight: "5px"}}>{isSmall ? '' : languagesCms.ADD_NEW}</Button>
                    </Link>
                    <Link to="/sessions">
                        <Button type="light" icon={<LeftOutlined/>} size="large"
                                style={{marginRight: "5px"}}>{isSmall ? '' : languagesCms.BACK}</Button>
                    </Link>
                </div>
            </div>
            <div className="table-wrap">
                <Search placeholder={languagesCms.SEARCH_SESSION} onChange={onChange} onSearch={(v) => {
                    setSearch(v);
                    get()
                }}/>
                <Table dataSource={data} columns={columns}
                       onChange={handleTableChange}
                       loading={{spinning: loading, indicator: <Loading3QuartersOutlined spin/>, size: "large"}}
                       pagination={{
                           total: totalCount
                       }}
                       rowSelection={{...rowSelection}}/>
            </div>

        </div>
    );
};

export default SessionsItemList;
