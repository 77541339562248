import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import api from '../service/index';
import qs from 'qs';

export default (props) => {
  let { record, setRecord, name } = props;
  let [roles, setRoles] = useState([]);
  let _params = {
    query: { deleted: { $ne: true } },
    sort: '-createdAt',
    page: 1,
    pageSize: 100000,
  };  let languagesCms = props.languagesCms;
  useEffect(() => {
    api
      .get(`/rest/attendees?${qs.stringify(_params)}`)
      .then(({ data }) => {
        if (data && data.result && data.result.rows.length > 0) {
          let dbRoles = data.result.rows.sort((a, b) =>
            a.name.localeCompare(b.name),
          );
          setRoles(dbRoles);
        }
      });
  }, []);

  return (
    <Select
      value={record[name] || []}
      placeholder={"Kullanıcı"}
      showSearch={true}
      onChange={(v) => setRecord({ ...record, [name]: v })}
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {roles &&
        roles.map((b) => (
          <Select.Option key={b._id} value={b._id}>
            {b.name +" "+b.lastname}
          </Select.Option>
        ))}
    </Select>
  );
};
