import React, { useEffect, useState, useGlobal } from 'reactn';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Card,
  message,
  Select,
  DatePicker,
  Switch
} from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { MapInput, CoordinateTypesPicker, DbSelect,GroupPicker } from '../components';
import api from '../service/index';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import moment from 'moment';

const { Option } = Select;

const FlightPlanDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let id = params.id !== 'add' ? params.id : false;
  let newRecord = {
    pnr: '',
    from: '',
    from_date: '',
    transfer: '',
    transfer_date: '',
    to: '',
    to_date: '',
    type: '',
    quota: 0,
    is_transfer: false,
  };

  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);

  let [modules] = useGlobal('modules');
  let [languagesCms] = useGlobal('languagesCms');
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api
        .get('/rest/flightPlan/' + id)
        .then(({ data: { result, result_message } }) => {
          setData(result);
          setLoading(false);
        });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    if (data.pnr === null || data.pnr.length === 0)
      errors.code = languagesCms.ENFORCED;

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: 'smooth' });
    } else {
      if (id) {
        api
          .put('/rest/flightPlan/' + id, data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
              history.push('/flight/plan');
            } else message.error(languagesCms.ERROR_SAVE_RECORD, 2);
          });
      } else {
        api
          .post('/rest/flightPlan', data)
          .then(({ data: { result, result_message } }) => {
            if (result_message.type === 'success') {
              message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
              history.push('/flight/plan');
            } else {
              message.error(result_message.message, 2);
            }
          });
      }
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>Uçuş</h1>
        </div>
        <div className="list-buttons">
          <Link to="/flight/plan">
            <Button type="light" icon={<LeftOutlined />} size="large">
              {languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card
          title={id ? languagesCms.EDIT : languagesCms.ADD_NEW}
          loading={loading}
        >
          <Form layout="horizontal" size={'large'} onFinish={save}>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Tip"
                  required
                  help={errors.type}
                  validateStatus={errors.type ? 'error' : 'success'}
                >
                  <Select
                    defaultValue={data.type}
                    style={{ width: 120 }}
                    onChange={(e) => setData({ ...data, type: e })}
                  >
                    <Option value="DEPARTURE">GİDİŞ</Option>
                    <Option value="ARRIVAL">DÖNÜŞ</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Uçuş Kodu"
                  required
                  help={errors.pnr}
                  validateStatus={errors.pnr ? 'error' : 'success'}
                >
                  <Input
                    name="pnr"
                    value={data.pnr}
                    onChange={(e) => setData({ ...data, pnr: e.target.value })}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Kalkış"
                  required
                  help={errors.from}
                  validateStatus={errors.from ? 'error' : 'success'}
                >
                  <DbSelect
                    url="flightAirport"
                    placeholder="Havalimanı"
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="from"
                    mode="single"
                  />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Kalkış Tarihi"
                  required
                  help={errors.from_date}
                  validateStatus={errors.from_date ? 'error' : 'success'}
                >
                  <DatePicker
                    format="DD-MM-YYYY HH:mm"
                    showTime
                    defaultValue={moment(data.from_date || new Date())}
                    style={{ width: 250, marginLeft: 5 }}
                    placeholder={languagesCms.SELECT_EARLIEST_DATE}
                    onChange={(e) => setData({ ...data, from_date: e })}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Aktarma"
                  required
                  help={errors.is_transfer}
                  validateStatus={errors.transfer ? 'error' : 'success'}
                >
                  <Switch
                    checked={data.is_transfer}
                    checkedChildren={`Aktarma Var`}
                    unCheckedChildren={`Aktarma Yok`}
                    onChange={(v) => setData({ ...data, is_transfer: v })}
                  />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Grup"
                  required
                  validateStatus={'success'}
                >
                  <GroupPicker languagesCms={languagesCms} record={data} setRecord={setData} name="groups" />
                  {/* <DbSelect
                    url="flightGroup"
                    placeholder="Grup"
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="group"
                    mode="single"
                  /> */}
                </Form.Item>
              </Col>
            </Row>
            {/* <Row direction="row">
              
            </Row> */}

            {data.is_transfer && (
              <>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      label="Aktarma PNR"
                      required
                      help={errors.pnr}
                      validateStatus={errors.pnr ? 'error' : 'success'}
                    >
                      <Input
                        name="transfer_pnr"
                        value={data.transfer_pnr}
                        onChange={(e) =>
                          setData({ ...data, transfer_pnr: e.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      label="Aktarma Kalkış"
                      required
                      help={errors.transfer}
                      validateStatus={errors.transfer ? 'error' : 'success'}
                    >
                      <DbSelect
                        url="flightAirport"
                        placeholder="Havalimanı"
                        languagesCms={languagesCms}
                        record={data}
                        setRecord={setData}
                        name="transfer"
                        mode="single"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item
                      label="Aktarma Kalkış Tarihi"
                      required
                      help={errors.transfer_date}
                      validateStatus={
                        errors.transfer_date ? 'error' : 'success'
                      }
                    >
                      <DatePicker
                        format="DD-MM-YYYY HH:mm"
                        showTime
                        defaultValue={moment(data.transfer_date || new Date())}
                        style={{ width: 250, marginLeft: 5 }}
                        placeholder={languagesCms.SELECT_EARLIEST_DATE}
                        onChange={(e) => setData({ ...data, transfer_date: e })}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="İniş"
                  required
                  help={errors.to}
                  validateStatus={errors.to ? 'error' : 'success'}
                >
                  <DbSelect
                    url="flightAirport"
                    placeholder="Havalimanı"
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="to"
                    mode="single"
                  />
                </Form.Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="İniş Tarihi"
                  required
                  help={errors.to_date}
                  validateStatus={errors.to_date ? 'error' : 'success'}
                >
                  <DatePicker
                    format="DD-MM-YYYY HH:mm"
                    showTime
                    defaultValue={moment(data.to_date || new Date())}
                    style={{ width: 250, marginLeft: 5 }}
                    placeholder={languagesCms.SELECT_EARLIEST_DATE}
                    onChange={(e) => setData({ ...data, to_date: e })}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Kota"
                  required
                  help={errors.quota}
                  validateStatus={errors.quota ? 'error' : 'success'}
                >
                  <Input
                    name="quota"
                    value={data.quota}
                    onChange={(e) =>
                      setData({ ...data, quota: e.target.value })
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label="Havayolu Şirketi"
                  required
                  help={errors.company}
                  validateStatus={errors.company ? 'error' : 'success'}
                >
                  <Select
                    value={data.company}
                    onChange={(e) => setData({ ...data, company: e })}
                  >
                    <Select.Option value="Türk Hava Yolları">
                      Türk Hava Yolları
                    </Select.Option>
                    <Select.Option value="Pegasus">Pegasus</Select.Option>
                    <Select.Option value="Anadolujet">Anadolujet</Select.Option>
                    <Select.Option value="OnurAir">OnurAir</Select.Option>
                    <Select.Option value="SunExpress">SunExpress</Select.Option>
                    <Select.Option value="FLYKHY">Kıbrıs Hava Yolları</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {' '}
                    {languagesCms.SAVE}{' '}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default FlightPlanDetail;
