export { default as UserList } from './UserList';
export { default as Threats } from './Theats';
export { default as Dashboard } from './Dashboard';
export { default as RedirectComp } from './RedirectComp';
/* Settings */
export { default as Setting } from './Settings/Setting';
export { default as Module } from './Settings/Module';
export { default as ModuleDetail } from './Settings/ModuleDetail';
export { default as Customization } from './Settings/Customization';
export { default as LoginSetting } from './Settings/LoginSetting';
export { default as SurveyWithMediaIcons } from './Settings/SurveyWithMediaIcons';
export { default as Version } from './Settings/Version';
export { default as TopRightModule } from './Settings/TopRightModule';
export { default as Deneme } from './Settings/Deneme';
export { default as DenemeDetail } from './Settings/DenemeDetail';
export { default as BottomBar } from './Settings/BottomBar';
export { default as BottomBarDetail } from './Settings/BottomBarDetail';
export { default as Languages } from './Settings/Languages';
export { default as LanguagesDetail } from './Settings/BottomBarDetail';
export { default as LanguagesCms } from './Settings/LanguagesCms';
export { default as LanguagesCmsDetail } from './Settings/BottomBarDetail';
export { default as LikeTypes } from './Settings/LikeTypes';
export { default as LikeTypesDetail } from './Settings/LikeTypesDetail';
export { default as CoordinateTypes } from './Settings/CoordinateTypes';
export { default as CoordinateTypesDetail } from './Settings/CoordinateTypesDetail';
export { default as PointTypes } from './Settings/PointTypes';
export { default as PointTypesDetail } from './Settings/PointTypesDetail';
export { default as ShakeWinMessages } from './Settings/ShakeWinMessages';
export { default as ShakeWinMessagesDetail } from './Settings/ShakeWinMessagesDetail';
export { default as ResultMessages } from './Settings/ResultMessages';
export { default as ResultMessagesDetail } from './Settings/ResultMessagesDetail';
export { default as CoordinateMenu } from './Settings/CoordinateMenu';
export { default as CoordinateMenuDetail } from './Settings/CoordinateMenuDetail';
export { default as PointMenu } from './Settings/PointMenu';
export { default as PointMenuDetail } from './Settings/PointMenuDetail';
export { default as SupportedLang } from './Settings/SupportedLang';
export { default as SupportedLangDetail } from './Settings/SupportedLangDetail';
export { default as Live } from './Settings/Live';
export { default as Nword } from './Settings/Nword';
export { default as Nword2 } from './Settings/Nword2';
export { default as WebApp } from './Settings/WebApp';
export { default as WebAppDetail } from './Settings/WebAppDetail';
export { default as PofileSettings } from './Settings/PofileSettings';
export { default as AppControls } from './Settings/AppControls';
export { default as HealthList } from './Settings/HealthList';
export { default as SmsAndEmailMessage } from './Settings/SmsAndEmailMessage';
export { default as SmsAndEmailMessageDetail } from './Settings/SmsAndEmailMessageDetail';
export { default as ProfilSettingsMenu } from './Settings/ProfilSettingsMenu';
export { default as ProfilSettingsMenuDetail } from './Settings/ProfilSettingsMenuDetail';
export { default as PostwallSettings } from './Settings/PostwallSettings';
export { default as TopRightChatModule } from './Settings/TopRightChatModule';
export { default as BirthdaySettings } from './Settings/BirthdaySettings';
export { default as JobStartDateSettings } from './Settings/JobStartDateSettings';
export { default as ReservationSettings } from './Settings/ReservationSettings';
export { default as ReservationSettingsBranch } from './Settings/ReservationSettingsBranch';
export { default as PageReservationSettings } from './Settings/PageReservationSettings';
export { default as PageReservationSettingsBranch } from './Settings/PageReservationSettingsBranch';
export { default as TopRightShareButton } from './Settings/TopRightShareButton';
export { default as PublicHolidays } from './Settings/PublicHolidays';
export { default as PublicHolidaysDetail } from './Settings/PublicHolidaysDetail';
export { default as PagePublicHolidays } from './Settings/PagePublicHolidays';
export { default as PagePublicHolidaysDetail } from './Settings/PagePublicHolidaysDetail';
export { default as ReservationAllSettings } from './Settings/ReservationAllSettings';
export { default as PageReservationDepartments } from './Settings/PageReservationDepartments';
export { default as PageReservationDepartmentsDetail } from './Settings/PageReservationDepartmentsDetail';
export { default as ReservationKVKKSettings } from './Settings/ReservationKVKKSettings';
export { default as PageReservationKVKKSettings } from './Settings/PageReservationKVKKSettings';
/* Settings */
export { default as PostWall } from './Postwall';
export { default as PostWallDetail } from './PostwallDetail';
export { default as Survey } from './Survey';
export { default as SurveyAnswers } from './SurveyAnswers';
export { default as SurveyDetail } from './SurveyDetail';
export { default as SurveyAnswersDetail } from './SurveyAnswersDetail';
export { default as SurveyAnswersDetailPrint } from './SurveyAnswersDetailPrint';
export { default as SurveysWithMedia } from './SurveysWithMedia';
export { default as SurveysWithMediaDetail } from './SurveysWithMediaDetail';
export { default as SurveysWithMediaAnswers } from './SurveysWithMediaAnswers';
export { default as SurveysWithMediaAnswersDetail } from './SurveysWithMediaAnswersDetail';
export { default as SurveysWithMediaAnswersDetailPrint } from './SurveysWithMediaAnswersDetailPrint';
export { default as SurveyDetailPrint } from './SurveyDetailPrint';
export { default as SurveyWithMediaDetailPrint } from './SurveyWithMediaDetailPrint';

export { default as SurveysWithMedia2 } from './SurveysWithMedia2';
export { default as SurveysWithMedia2Detail } from './SurveysWithMedia2Detail';
export { default as SurveysWithMedia2Answers } from './SurveysWithMedia2Answers';
export { default as SurveysWithMedia2AnswersDetail } from './SurveysWithMedia2AnswersDetail';
export { default as SurveysWithMedia2AnswersDetailPrint } from './SurveysWithMedia2AnswersDetailPrint';
export { default as SurveyWithMedia2DetailPrint } from './SurveyWithMedia2DetailPrint';

export { default as ShakeWinCategory } from './ShakeWinCategory';
export { default as ShakeWinCategoryDetail } from './ShakeWinCategoryDetail';
export { default as ShakeWinItem } from './ShakeWinItem';
export { default as ShakeWinItemDetail } from './ShakeWinItemDetail';

export { default as Podcasts } from './Podcasts';
export { default as PodcastsDetail } from './PodcastsDetail';
export { default as PodcastItems } from './PodcastItems';
export { default as PodcastItemsDetail } from './PodcastItemsDetail';

export { default as Event } from './Event';
export { default as EventDetail } from './EventDetail';
export { default as Event2 } from './Event2';
export { default as Event2Detail } from './Event2Detail';
export { default as Meeting } from './Meeting';
export { default as MeetingDetail } from './MeetingDetail';
export { default as Coordinate } from './Coordinate';
export { default as CoordinateDetail } from './CoordinateDetail';
export { default as RoomMate } from './RoomMate';
export { default as RoomMateDetail } from './RoomMateDetail';
export { default as Flight } from './Flight';
export { default as FlightDetail } from './FlightDetail';
export { default as FlightCities } from './FlightCities';
export { default as FlightCitiesDetail } from './FlightCitiesDetail';
export { default as FlightAirport } from './FlightAirport';
export { default as FlightAirportDetail } from './FlightAirportDetail';
export { default as FlightPlan} from './FlightPlan';
export { default as FlightPlanDetail } from './FlightPlanDetail';
export { default as FlightService} from './FlightService';
export { default as FlightServiceDetail} from './FlightServiceDetail';
export { default as Point } from './Point';
export { default as PointDetail } from './PointDetail';
export { default as Campaing } from './Campaing';
export { default as CampaingDetail } from './CampaingDetail';
export { default as News } from './News';
export { default as NewsDetail } from './NewsDetail';
export { default as Information } from './Information';
export { default as InformationDetail } from './InformationDetail';
export { default as InformationSubList } from './InformationSubList';
export { default as InformationSubDetail } from './InformationSubDetail';
export { default as Topic } from './Topic';
export { default as TopicDetail } from './TopicDetail';
export { default as Education } from './Education';
export { default as EducationDetail } from './EducationDetail';
export { default as ServiceRoute } from './ServiceRoute';
export { default as ServiceRouteDetail } from './ServiceRouteDetail';
export { default as PersonalityInventory } from './PersonalityInventory';
export { default as PersonalityInventoryDetail } from './PersonalityInventoryDetail';
export { default as SubTopic } from './SubTopic';
export { default as SubTopicDetail } from './SubTopicDetail';
export { default as Storie } from './Storie';
export { default as StorieDetail } from './StorieDetail';
export { default as PushNotification } from './PushNotification';
export { default as PushNotificationDetail } from './PushNotificationDetail';
export { default as BusinessCategory } from './BusinessCategory';
export { default as BusinessCategoryDetail } from './BusinessCategoryDetail';
export { default as Banner } from './Banner';
export { default as BannerDetail } from './BannerDetail';
export { default as BloodNeeds } from './BloodNeeds';
export { default as BloodNeedsDetail } from './BloodNeedsDetail';
export { default as AttendeeGroup } from './AttendeeGroup';
export { default as AttendeeGroupDetail } from './AttendeeGroupDetail';
export { default as AttendeeGroupList } from './AttendeeGroupList';
export { default as AttendeeGroupModules } from './AttendeeGroupModules';
export { default as ActivityAttendeeList } from './ActivityAttendeeList';
export { default as Attendee } from './Attendee';
export { default as AttendeeDetail } from './AttendeeDetail';

export { default as AdminRole } from './AdminRole';
export { default as AdminRoleDetail } from './AdminRoleDetail';

export { default as EducationSurveyTemplate } from './EducationSurveyTemplate';
export { default as EducationSurveyTemplateDetail } from './EducationSurveyTemplateDetail';
export { default as Lesson } from './Lesson';
export { default as LessonDetail } from './LessonDetail';
export { default as PhotoAlbum } from './photoAlbum';
export { default as PhotoAlbumDetail } from './PhotoAlbumDetail';
export { default as PhotoAlbumSubCategoryList } from './PhotoAlbumSubCategoryList';
export { default as PhotoAlbumSubCategoryDetail } from './PhotoAlbumSubCategoryDetail';
export { default as PhotoAlbumItemList } from './PhotoAlbumItemList';
export { default as PhotoAlbumItemDetail } from './PhotoAlbumItemDetail';
export { default as PhotoAlbumItemDetailMulti } from './PhotoAlbumItemDetailMulti';
export { default as PhotoAlbum2 } from './photoAlbum2';
export { default as PhotoAlbum2Detail } from './PhotoAlbum2Detail';
export { default as PhotoAlbum2ItemList } from './PhotoAlbum2ItemList';
export { default as PhotoAlbum2ItemDetail } from './PhotoAlbum2ItemDetail';
export { default as PhotoAlbum2ItemDetailMulti } from './PhotoAlbum2ItemDetailMulti';
export { default as VideoAlbum } from './VideoAlbum';
export { default as VideoAlbumDetail } from './VideoAlbumDetail';
export { default as VideoAlbumItemList } from './VideoAlbumItemList';
export { default as VideoAlbumItemDetail } from './VideoAlbumItemDetail';
export { default as OurNews } from './OurNews';
export { default as OurNewsDetail } from './OurNewsDetail';
export { default as ActivityCategory } from './ActivityCategory';
export { default as ActivityCategoryDetail } from './ActivityCategoryDetail';
export { default as Activity } from './Activity';
export { default as ActivityDetail } from './ActivityDetail';
export { default as Speaker } from './Speaker';
export { default as SpeakerDetail } from './SpeakerDetail';
export { default as Speaker2 } from './Speaker2';
export { default as Speaker2Detail } from './Speaker2Detail';
export { default as SpeakerOrder } from './SpeakerOrder';
export { default as SpeakerOrderDetail } from './SpeakerOrderDetail';
export { default as Sponsor } from './Sponsor';
export { default as SponsorDetail } from './SponsorDetail';
export { default as Exhibitor } from './Exhibitor';
export { default as ExhibitorDetail } from './ExhibitorDetail';
export { default as NotFoundPage } from './404';
export { default as Barcod } from './Barcod';
export { default as Sessions } from './Sessions';
export { default as SessionsDetail } from './SessionsDetail';
export { default as SessionsItemList } from './SessionsItemList';
export { default as SessionsItemDetail } from './SessionsItemDetail';
export { default as Sessions2 } from './Sessions2';
export { default as Sessions2Detail } from './Sessions2Detail';
export { default as Sessions2ItemList } from './Sessions2ItemList';
export { default as Sessions2ItemDetail } from './Sessions2ItemDetail';
export { default as Competitions } from './Competitions';
export { default as CompetitionsDetail } from './CompetitionsDetail';
export { default as CompetitionAnswers } from './CompetitionAnswers';
export { default as CompetitionAnswersDetail } from './CompetitionAnswersDetail';
export { default as OpenPositions } from './OpenPositions';
export { default as OpenPositionsDetail } from './OpenPositionsDetail';
export { default as Place } from './Place';
export { default as PlaceDetail } from './PlaceDetail';
export { default as Plan } from './Plan';
export { default as PlanDetail } from './PlanDetail';
export { default as PlanCoordinates } from './PlanCoordinates';
export { default as PlanReservation } from './PlanReservation';
export { default as PlanReservationDetail } from './PlanReservationDetail';
export { default as PlanReservationReport } from './PlanReservationReport';
export { default as Logs } from './Logs';
export { default as Secondhand } from './Secondhand';
export { default as SecondhandDetail } from './SecondhandDetail';
export { default as Contact } from './Contact';
export { default as ContactDetail } from './ContactDetail';
export { default as Like } from './Like';
export { default as Comment } from './Comment';
export { default as CommentDetail } from './CommentDetail';
export { default as PlaceReport } from './PlaceReport';
export { default as EmailSender } from './EmailSender';
export { default as EmailSenderDetail } from './EmailSenderDetail';
export { default as Info6 } from './Info6';
export { default as Info6Detail } from './Info6Detail';
export { default as Info6SubList } from './Info6SubList';
export { default as Info6SubDetail } from './Info6SubDetail';
export { default as SessionOrderCategory } from './SessionOrderCategory';
export { default as SessionOrderCategoryDetail } from './SessionOrderCategoryDetail';
export { default as SessionOrderSubCategory } from './SessionOrderSubCategory';
export { default as SessionOrderItem } from './SessionOrderItem';
export { default as SessionOrderSubCategoryDetail } from './SessionOrderSubCategoryDetail';
export { default as SessionOrderItemDetail } from './SessionOrderItemDetail';

export { default as IsbankAttendee } from './IsbankAttendee';
export { default as IsbankAttendeeDetail } from './IsbankAttendeeDetail';

export { default as StorieLike } from './StorieLike';
export { default as StorieComment } from './StorieComment';
export { default as StorieCommentDetail } from './StorieCommentDetail';

export { default as Activity2Category } from './Activity2Category';
export { default as Activity2CategoryDetail } from './Activity2CategoryDetail';
export { default as Activity2 } from './Activity2';
export { default as Activity2Detail } from './Activity2Detail';
export { default as Activity2AttendeeList } from './Activity2AttendeeList';

export { default as PushNotificationExcel } from './PushNotificationExcel';

export { default as Popup } from './Popup';
export { default as PopupDetail } from './PopupDetail';
export { default as EventAttendeeList } from './EventAttendeeList';

export { default as Event2AttendeeList } from './Event2AttendeeList';

export { default as FaceImages } from './faces/Images';
export { default as ARVideo } from './ARVideo';
export { default as ARVideoDetail } from './ARVideoDetail';

export { default as Event3 } from './Event3';
export { default as Event3Detail } from './Event3Detail';


export { default as EventTableReservation } from './EventTableReservation';
export { default as EventTableReservationDetail } from './EventTableReservationDetail';
export { default as EventTableReservationUserList } from './EventTableReservationUserList';
export { default as EventTableReservationUserDetail } from './EventTableReservationUserDetail';
export { default as EventTableReservationUserAdd } from './EventTableReservationUserAdd';